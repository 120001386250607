@import '../../styles/variable.scss';
@import '../../styles/mixins.scss';

.appointment-form-wrapper, .success-message {
  padding: 60px 0;
  @include media-breakpoint-down($lg) {
    padding: 40px 0;
  }
  .form-header {
    h3 {
      @include typography(32px, 600, 40px, $web-font-bold, $color-black);
      margin: 0 0 30px;
    }
  }
  .two-column {
    align-items: start;
  }
  .img-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 30px;
    min-height: 450px;
    background: url(../../assets/images/servicebg.jpeg) no-repeat;
    background-size: 100% 100%;
    border-radius: 12px;
    color: $color-white;
    position: sticky;
    top: 12px;
    @include media-breakpoint-down($lg) {
      display: none;
    }
  }
  .google-service-form {
    .step-navigation-wrapper {
      position: relative;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 4px;
      margin-bottom: 50px;
      width: 100%;
      .step-navigation-item {
        display: flex;
        flex-direction: column;
        position: relative;
        background: transparent;
        border: 0;
        outline: none;
        box-shadow: none;
        padding: 20px 0 0;
        text-align: left;
        opacity: 0.5;
        &.active {
          opacity: 1;
        }
        &::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 4px;
          top: 0;
          left: 0;
          background: rgba(237, 51, 54, 0.7);
        }
        span {
          @include typography(12px, 600, 20px, $web-font-bold, $color-primary);
        }
        @include typography(16px, 600, 20px, $web-font-medium, $color-black);
        @include media-breakpoint-down($lg) {
          font-size: 10px;
        }
      }
    }
      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .step-btn {
          display: block;
          width: 150px;
          text-align: center;
          padding: 12px 15px;
          border-radius: 8px;
          outline: none;
          box-shadow: none;
          cursor: pointer;
          @include typography(16px, 600, 18px, $web-font, $color-white);
          &:disabled {
            opacity: 0.5;
            background: $color-grey;
          }
          &.btn-continue {
            margin-left: auto;
            background: $color-primary;
            border: 0;
            padding-right: 30px;
            position: relative;
            &::before {
              position: absolute;
              content: '\f061';
              font-family: 'FontAwesome';
              top: 50%;
              right: 15px;
              transform: translateY(-50%);
            }
          }
          &.btn-back {
            border: 1px solid $color-black;
            background: transparent;
            color: $color-black;
          }
          &.btn-submit {
            background: $color-primary;
            border: 0;
          }
        }
      }
    .step-form-panel {
      display: none;
      &.show-panel {
        display: block;
      }
    }
    .form-element {
      margin-bottom: 30px;
      .form-label {
        @include typography(16px, 600, 20px, $web-font-medium, $color-primary);
        margin-bottom: 12px;
        display: block;
      }
      .form-input {
        width: 100%;
        padding: 15px 10px;
        @include typography(16px, 500, 20px, $web-font, $color-black);
        outline: none;
        box-shadow: none;
        border: none;
        border-radius: 8px;
        box-shadow: 0px 0px 8px rgba($color: $color-black, $alpha: 0.2);
        background-color: $color-white;
        appearance: none;
        display: block;
        &.form-message {
          resize: none;
          min-height: 250px;
        }
      }
      input[type="date"], input[type="time"] {
        position: relative;
        padding: 10px;
      }
      
      input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
        color: transparent;
        background: none;
        z-index: 1;
      }
      
      input[type="date"]:before {
        color: transparent;
        background: none;
        display: block;
        font-family: 'FontAwesome';
        content: '\f073';
        width: 15px;
        height: 20px;
        position: absolute;
        top: 12px;
        right: 10px;
        color: $color-black;
      }

      input[type="time"]:before {
        color: transparent;
        background: none;
        display: block;
        font-family: 'FontAwesome';
        content: '\f017';
        width: 15px;
        height: 20px;
        position: absolute;
        top: 12px;
        right: 10px;
        color: $color-black;
      }

      select {
        appearance: none;
        position: relative;
        background: url(../../assets/icons/down-arrow.png) no-repeat right center;
        background-size: 25px;
        background-position-x: 98%;
        &::before {
          position: absolute;
          content: '';
          width: 15px;
          height: 15px;
          top: 50%;
          right: 12px;
          transform: translateY(-50%)
        }
      }
      textarea {
        resize: none;
        height: 250px;
      }
      .error-message {
        @include typography(16px, 600, 20px, $web-font-medium, $color-secondary);
        margin: 10px 0;
      }
      .agreed-terms {
        a {
          color: $color-primary;
          text-decoration: underline;
        }
      }
    }
  }

}


.success-message {
  .content {
    h3 {
      @include typography(32px, 600, 40px, $web-font-bold, $color-black);
      margin: 0 0 30px;
    }
    .btn-resubmit {
      position: relative;
      padding: 12px 0px 12px 25px;
      border: 0;
      outline: none;
      box-shadow: none;
      background: transparent;
      @include typography(14px, 600, 20px, $web-font-bold, $color-primary);
      cursor: pointer;
      &::before {
        position: absolute;
        content: '\f01e';
        font-family: "FontAwesome";
        width: 20px;
        height: 20px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        color: $color-primary;
      }
    } 
  }
}