@import '../../styles/variable.scss';
@import '../../styles/mixins.scss';

.footer-wrapper {
  position: relative;
  padding-top: 80px;
  background-color: $color-light-grey;
  border-top: 1px solid $color-black;
  @include media-breakpoint-down($lg) {
    display: none;
  }
  .footer-top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 200px;
    padding-bottom: 40px;
    .footer-logo {
      max-width: 200px;
    }
    .useful-links {
      h3 {
        @include typography(18px, 600, 14px, $web-font-bold, $color-black);
        margin: 0 0 25px;
      }
      ul {
        li {
          a {
            @include typography(16px, 500, 21px, $web-font-medium, $color-grey);
            margin: 8px 0;
            display: block;
            transition: all 0.5s ease;
            &:hover {
              color: $color-secondary;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    text-align: center;
    p {
      @include typography(14px, 500, 14px, $web-font, $color-grey);
      margin: 0;
      padding: 12px 0;
      border-top: 1px solid $color-grey;
    }
  }
}