@import '../../styles/variable.scss';
@import '../../styles/mixins.scss';

.image-content-wrapper {
  padding: 60px 0;
  background: $color-light-grey;
  .image-grid-column {
    display: grid;
    grid-template-columns: 35% 65%;
    gap: 30px;
    align-items: center;
    @include media-breakpoint-down($lg) {
      grid-template-columns: 1fr;
      .image-wrapper {
        text-align: center;
      }
    }
  }
  .image-wrapper img {
    border-radius: 100%;
  }
  .content-info {
    h3 {
      @include typography(32px, 600, 40px, $web-font-bold, $color-black);
      margin: 0 0 30px;
      @include media-breakpoint-down($lg) {
        font-size: 21px;
        line-height: 24px;
      }
    }
    p {
      @include typography(18px, 500, 20px, $web-font-medium, $color-grey);
      margin: 0 0 25px;
      @include media-breakpoint-down($lg) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}