@import '../../styles/variable.scss';
@import '../../styles/mixins.scss';

.contact-info-wrapper {
  padding: 60px 0;
  @include media-breakpoint-down($lg) {
    padding: 40px 0;
  }
  .contact-header {
    text-align: center;
    .content-label {
      @include typography(16px, 500, 26px, $web-font, $color-primary);
      margin-bottom: 5px;
      @include media-breakpoint-down($lg) {
        font-size: 14px;
        font-weight: 600px;
        font-family: $web-font-bold;
      }
    }
    h3 {
      @include typography(32px, 600, 40px, $web-font-bold, $color-black);
      margin: 0;
      @include media-breakpoint-down($lg) {
        font-size: 21px;
        line-height: 24px;
      }
    }
  }
  .contact-info-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 50px 0 0;
    column-gap: 30px;
    @include media-breakpoint-down($lg) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 30px;
      margin: 30px 0 0;
    }
    .contact-info-item {
      box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      padding: 50px 20px;
      text-align: center;
      transition: 0.4s;
      background: $color-white;
      .item-icon {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.4s;
        background: rgba(251, 124, 86, 0.1);
        color: $color-primary;
      }
      h4 {
        @include typography(20px, 700, 32px, $web-font-bold, $color-dark-blue);
        margin: 25px 0 15px;
        @include media-breakpoint-down($lg) {
          font-weight: 18px;
          line-height: 24px;
        }
      }
      p {
        @include typography(16px, 500, 26px, $web-font, $color-grey);
        margin: 0;
      }
    }
  }
}