@import '../../styles/variable.scss';
@import '../../styles/mixins.scss';

.loader-network-wrapper {
  position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  background: rgba(255, 255, 255, 1);
  transition: opacity 0.5s ease-in-out, z-index 0.5s step-end; // Add transition for opacity and z-index
  opacity: 1;
	&.fade-out {
		opacity: 0;
  z-index: -1;
	}
	.loader-wrapper, .network-check {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.network-check {
		position: relative;
		padding-top: 200px;
    text-align: center;
		&::before {
			position: absolute;
			content: '';
			max-width: 300px;
			width: 100%;
			height: 180px;
			top: 0;
			background: url(../../assets/icons/no-wifi.png) no-repeat;
			background-position: center;
			background-size: contain;
		}
		h2 {
      @include typography(32px, 600, 40px, $web-font-bold, $color-secondary);
			margin: 0 0 20px;
		}
		p {
      @include typography(18px, 500, 20px, $web-font-medium, $color-dark-grey);
			margin: 0 0 20px;
		}
		.btn-retry {
			border-radius: 40px;
			padding: 12px 15px 12px 45px;
			position: relative;
			&::before {
        position: absolute;
        content: '\f01e';
        font-family: "FontAwesome";
        width: 20px;
        height: 20px;
				left: 10px;
				top: 0;
        color: $color-white;
			}
			@include media-breakpoint-up($lg) {
				&:hover {
					&::before {
						color: $color-secondary;
					}
				}
			}
		}
	}
	
	.network-check button {
		padding: 0.5em 1em;
		font-size: 1em;
		cursor: pointer;
	}
}
.loader-wrapper {
	background: #fff;
	color: $color-primary;
	font: bold 1em/1.5 "Comfortaa", sans-serif;
	display: grid;
	place-items: center;
	align-content: center;
	height: 100vh;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	p {
		color: $color-secondary;
	}
}
.hexagon {
	margin-bottom: 1.5em;
	overflow: hidden;
	position: relative;
	width: 15em;
	height: 15em;
}
.hexagon__group, .hexagon__sector, .hexagon__sector:before, .hexagon__sector:after {
	position: absolute;
}
.hexagon__group {
	width: 100%;
	height: 100%;
}
.hexagon__group:nth-child(2) .hexagon__sector,
.hexagon__group:nth-child(2) .hexagon__sector:before,
.hexagon__group:nth-child(2) .hexagon__sector:after {
	animation-delay: calc(8s * -1/6);
}
.hexagon__group:nth-child(3) .hexagon__sector,
.hexagon__group:nth-child(3) .hexagon__sector:before,
.hexagon__group:nth-child(3) .hexagon__sector:after {
	animation-delay: calc(8s * -2/6);
}
.hexagon__group:nth-child(4) .hexagon__sector,
.hexagon__group:nth-child(4) .hexagon__sector:before,
.hexagon__group:nth-child(4) .hexagon__sector:after {
	animation-delay: calc(8s * -3/6);
}
.hexagon__group:nth-child(5) .hexagon__sector,
.hexagon__group:nth-child(5) .hexagon__sector:before,
.hexagon__group:nth-child(5) .hexagon__sector:after {
	animation-delay: calc(8s * -4/6);
}
.hexagon__group:nth-child(6) .hexagon__sector,
.hexagon__group:nth-child(6) .hexagon__sector:before,
.hexagon__group:nth-child(6) .hexagon__sector:after {
	animation-delay: calc(8s * -5/6);
}
.hexagon__group:nth-child(odd) {
	transform: rotate(30deg);
}
.hexagon__sector, .hexagon__sector:before, .hexagon__sector:after {
	animation-duration: 8s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	/*animation-play-state: paused;*/
	width: 0.2em;
	height: 0.2em;
}
.hexagon__sector {
	animation-name: moveOut1;
	top: calc(50% - 0.1em);
	left: calc(50% - 0.1em);
}
.hexagon__sector:nth-child(2) {
	animation-name: moveOut2;
}
.hexagon__sector:nth-child(3) {
	animation-name: moveOut3;
}
.hexagon__sector:nth-child(4) {
	animation-name: moveOut4;
}
.hexagon__sector:nth-child(5) {
	animation-name: moveOut5;
}
.hexagon__sector:nth-child(6) {
	animation-name: moveOut6;
}
.hexagon__sector:before, .hexagon__sector:after {
	animation-name: rippleLine;
	background-color: currentColor;
	border-radius: 0.1em;
	content: "";
	display: block;
	top: 0;
	left: 0;
	transform-origin: 0.1em 0.1em;
}
.hexagon__sector:before {
	transform: rotate(-30deg)
}
.hexagon__sector:after {
	transform: rotate(-150deg)
}
/* Animations */
@keyframes moveOut1 {
	from { transform: translateY(0) scale(0); }
	3% { transform: translateY(0.2em) scale(1); }
	97% { transform: translateY(7.3em) scale(1); }
	to { transform: translateY(7.5em) scale(0); }
}
@keyframes moveOut2 {
	from { transform: rotate(60deg) translateY(0) scale(0); }
	3% { transform: rotate(60deg) translateY(0.2em) scale(1); }
	97% { transform: rotate(60deg) translateY(7.3em) scale(1); }
	to { transform: rotate(60deg) translateY(7.5em) scale(0); }
}
@keyframes moveOut3 {
	from { transform: rotate(120deg) translateY(0) scale(0); }
	3% { transform: rotate(120deg) translateY(0.2em) scale(1); }
	97% { transform: rotate(120deg) translateY(7.3em) scale(1); }
	to { transform: rotate(120deg) translateY(7.5em) scale(0); }
}
@keyframes moveOut4 {
	from { transform: rotate(180deg) translateY(0) scale(0); }
	3% { transform: rotate(180deg) translateY(0.2em) scale(1); }
	97% { transform: rotate(180deg) translateY(7.3em) scale(1); }
	to { transform: rotate(180deg) translateY(7.5em) scale(0); }
}
@keyframes moveOut5 {
	from { transform: rotate(240deg) translateY(0) scale(0); }
	3% { transform: rotate(240deg) translateY(0.2em) scale(1); }
	97% { transform: rotate(240deg) translateY(7.3em) scale(1); }
	to { transform: rotate(240deg) translateY(7.5em) scale(0); }
}
@keyframes moveOut6 {
	from { transform: rotate(300deg) translateY(0) scale(0); }
	3% { transform: rotate(300deg) translateY(0.2em) scale(1); }
	97% { transform: rotate(300deg) translateY(7.3em) scale(1); }
	to { transform: rotate(300deg) translateY(7.5em) scale(0); }
}
@keyframes rippleLine {
	from, to { width: 0.2em; }
	33% { width: 2.4em; }
}