@import '../../styles/variable.scss';
@import '../../styles/mixins.scss';

.social-media-wrapper {
  display: flex;
  gap: 20px;
  margin: 25px 0;
  li {
    i {
      @include typography(14px, 500, 14px, $web-font, $color-grey);
      padding: 10px;
      transition: all 0.5s ease;
      border: 1px solid $color-black;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease;
      cursor: pointer;
      &:hover {
        border-color: $color-secondary;
        color: $color-white;
        background: $color-secondary;
      }
    }
  }
}